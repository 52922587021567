import React from "react";
import Card from "./Card";
import { SiJavascript, SiReact, SiCss3, SiHtml5 } from "react-icons/si";
import { TbApi } from "react-icons/tb";
import { SiTailwindcss } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
import proyecto_pokemon from "../../assets/webp/proyecto_pokemon.webp";
import proyecto_valentina from "../../assets/webp/proyecto_valentina.webp";
import proyecto_currencyConverter from "../../assets/webp/proyecto_curencyConverter.webp";
import proyecto_andescap from "../../assets/webp/proyecto_andescap.webp";
import proyecto_teclado from "../../assets/webp/proyecto_teclado.webp";
import proyecto_perdoname from "../../assets/webp/proyecto_perdoname.webp";
import { useTheme } from "../../context/ThemeContext";
function Cards() {
  const proyectos = [
    {
      titulo: "React, Sass,CSS",
      proyectos: [
        {
          nombre: "Pokedex online",
          ruta: "https://github.com/rcuevaspantoja/pokedex",
          imagen: proyecto_pokemon,
          descripcion:
            "Sitio web con consumo de la API de Pokémon para buscar Pokémon, su número de ID y su tipo, con un infinite scrolling de la pokedex.",
          tecnologias: (
            <>
              <SiHtml5 /> <SiCss3 /> <SiReact /> <SiJavascript /> <TbApi />{" "}
            </>
          ),
        },
        {
          nombre: "Currency Converter",
          ruta: "https://github.com/rcuevaspantoja/currency-converter",
          imagen: proyecto_currencyConverter,
          descripcion: "Sitio de conversión de divisas extranjeras en ingles.",
          tecnologias: (
            <>
              <SiHtml5 /> <SiCss3 /> <SiReact /> <SiJavascript /> <TbApi />{" "}
            </>
          ),
        },
        {
          nombre: "Links de psicologia",
          ruta: "https://github.com/rcuevaspantoja/Biolinks-PsValentinaMoyano",
          imagen: proyecto_valentina,
          descripcion:
            "Sitio web para biografía de Instagram y redirigir a potenciales clientes.",
          tecnologias: (
            <>
              <SiHtml5 /> <SiCss3 /> <SiReact /> <SiJavascript />{" "}
            </>
          ),
        },
      ],
    },
    {
      titulo: "NextJS",
      proyectos: [
        {
          nombre: "Portal Andes Capital",
          ruta: "https://andes-cap-rcuevaspantojas-projects.vercel.app/",
          imagen: proyecto_andescap,
          descripcion:
            "Sitio web simple inspirado en su página actual tratando de darle un look más moderno y contemporaneo, sin agregar nueva data o estilos.",
          tecnologias: (
            <>
              <TbBrandNextjs /> <SiJavascript /> <SiTailwindcss />
            </>
          ),
        },
        {
          nombre: "Disculpame.org",
          ruta: "https:disculpame.org/",
          imagen: proyecto_perdoname,
          descripcion:
            "Sitio web cómico utilizado para pedir perdón con algo de gracia. Dinamico con rutas basadas en id.",
          tecnologias: (
            <>
              <TbBrandNextjs /> <SiTypescript /> <SiTailwindcss />
            </>
          ),
        },
      ],
    },
    {
      titulo: "Otros",
      proyectos: [
        {
          nombre: "Personal QMK Layout",
          ruta: "https://github.com/rcuevaspantoja/personal-cornekeyboard-layout",
          imagen: proyecto_teclado,
          descripcion:
            "Configuración de mi teclado personal programado en C, con instrucciones de compilación.",
          tecnologias: <></>,
        },
      ],
    },
  ];

  const { isDarkMode } = useTheme();
  return (
    <>
      {proyectos.map((tecnologia) => {
        return (
          <div className="contenedor">
            <h3 className={`proyectoTitulo ${isDarkMode ? "dark" : ""}`}>
              {tecnologia.titulo}
            </h3>
            <div className="cardDiv">
              {tecnologia.proyectos.map((card) => (
                <Card
                  nombre={card.nombre}
                  ruta={card.ruta}
                  imagen={card.imagen}
                  descripcion={card.descripcion}
                  tecnologias={card.tecnologias}
                />
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Cards;
